<template>
    <div class="CalSettings full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.calSettings.temperatureTolerance') }}</p>
                        </v-col>
                        <v-col class="col-3">
                            <text-input inputName="calSettings-temperatureTolerance" prefix="+/-" suffix="°C"
                                        onlyNumbers @onInputFocus="onInputFocus"
                                        :readonly="!checkUserRights('systemCalSettingsEdit')"
                                        :class="{'noAction': !checkUserRights('systemCalSettingsEdit')}"
                                        :rules="[v => !!v || $t('validation.required'), rules.temperatureTolerance, rules.maxDecimalPlaces1]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.calSettings.discHeightTolerance') }}</p>
                        </v-col>
                        <v-col class="col-3">
                            <text-input inputName="calSettings-discHeightTolerance" prefix="+/-" suffix="mm" onlyNumbers
                                        @onInputFocus="onInputFocus"
                                        :readonly="!checkUserRights('systemCalSettingsEdit')"
                                        :class="{'noAction': !checkUserRights('systemCalSettingsEdit')}"
                                        :rules="[v => !!v || $t('validation.required'), rules.discHeightTolerance, rules.maxDecimalPlaces1]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="calSettings-calibrationIntervalStatus"
                                           :disabled="!checkUserRights('systemCalSettingsEdit')"
                                           :class="{'active': calibrationIntervalStatus}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-3">
                            <p>{{ $t('system.calSettings.calibrationInterval') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': !calibrationIntervalStatus}">
                            <p>{{ $t('system.calSettings.intervalHeight') }}</p>
                        </v-col>
                        <v-col class="col-3">
                            <text-input inputName="calSettings-calibrationInterval-height" @onInputFocus="onInputFocus"
                                        onlyNumbers :disabled="!calibrationIntervalStatus"
                                        label="system.calSettings.days"
                                        :readonly="!checkUserRights('systemCalSettingsEdit')"
                                        :class="{'noAction': !checkUserRights('systemCalSettingsEdit')}"
                                        :rules="[rules.interval]"/>
                        </v-col>
                        <v-col class="col-1">
                        </v-col>
                        <v-col class="col-2" :class="{'disabled': !calibrationIntervalStatus}">
                            <p>{{ $t('system.calSettings.intervalTemperature') }}</p>
                        </v-col>
                        <v-col class="col-3">
                            <text-input inputName="calSettings-calibrationInterval-temperature"
                                        @onInputFocus="onInputFocus" onlyNumbers :disabled="!calibrationIntervalStatus"
                                        label="system.calSettings.days"
                                        :readonly="!checkUserRights('systemCalSettingsEdit')"
                                        :class="{'noAction': !checkUserRights('systemCalSettingsEdit')}"
                                        :rules="[rules.interval]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': !calibrationIntervalStatus}">
                            <p>{{ $t('system.calSettings.intervalBasketPosition') }}</p>
                        </v-col>
                        <v-col class="col-3">
                            <text-input inputName="calSettings-calibrationInterval-position"
                                        @onInputFocus="onInputFocus" onlyNumbers :disabled="!calibrationIntervalStatus"
                                        label="system.calSettings.days"
                                        :readonly="!checkUserRights('systemCalSettingsEdit')"
                                        :class="{'noAction': !checkUserRights('systemCalSettingsEdit')}"
                                        :rules="[rules.interval]"/>
                        </v-col>
                        <v-col class="col-1">
                        </v-col>
                        <v-col class="col-2" :class="{'disabled': !calibrationIntervalStatus}">
                            <p>{{ $t('system.calSettings.intervalFrequency') }}</p>
                        </v-col>
                        <v-col class="col-3">
                            <text-input inputName="calSettings-calibrationInterval-frequency"
                                        @onInputFocus="onInputFocus" onlyNumbers :disabled="!calibrationIntervalStatus"
                                        label="system.calSettings.days"
                                        :readonly="!checkUserRights('systemCalSettingsEdit')"
                                        :class="{'noAction': !checkUserRights('systemCalSettingsEdit')}"
                                        :rules="[rules.interval]"/>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToSystem"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress"
                           :class="{'disabled': saveDisabled || !checkUserRights('systemCalSettingsEdit')}"
                           @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import {mapState} from "vuex";
import TextInput from "@/components/TextInput.vue";
import mixins from "@/mixins/mixins";

export default {
    name: 'CalSettings',
    components: {
        TextInput,
        SwitchOnOff,
        FooterButton,
    },
    props: {},
    data() {
        return {
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            original: {},
            rules: {
                temperatureTolerance: v => {
                    if (v) {
                        return (parseFloat(v) >= 0 && parseFloat(v) <= 5.0) || `${this.$t('validation.minimum')}: 0, ${this.$t('validation.maximum')}: 5.0`;
                    } else return true;
                },
                discHeightTolerance: v => {
                    if (v) {
                        return (parseFloat(v) >= 0 && parseFloat(v) <= 2.0) || `${this.$t('validation.minimum')}: 0, ${this.$t('validation.maximum')}: 2.0`;
                    } else return true;
                },
                interval: v => {
                    if (this.calSettings.calibrationIntervalStatus) {
                        let numeric = /^[0-9]+$/;
                        if (numeric.test(v) && parseFloat(v) >= 1 && parseFloat(v) <= 366) {
                            return !!v;
                        } else {
                            return `${this.$t('validation.minimum')}: 1, ${this.$t('validation.maximum')}: 366`
                        }
                    } else return true;
                },
                maxDecimalPlaces1: v => {
                    if (v) {
                        let test = v.toString().split('.');
                        if (test[1]) {
                            if (test[1].length > 1) {
                                return `${this.$t('validation.chooseShorterDecimalPlace')}`;
                            }
                        }
                        return true;
                    } else return true;
                }
            }
        }
    },
    computed: {
        ...mapState([
            'calSettings',
        ]),
        calibrationIntervalStatus() {
            return this.$store.state.calSettings.calibrationIntervalStatus;
        },
    },
    methods: {
        goToSystem() {
            this.$router.push('system');
        },
        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.showSaveProgress = true;
                let CalSettings = {}
                if (this.calSettings.temperatureTolerance != this.original.temperatureTolerance && this.calSettings.temperatureTolerance != "") {
                    CalSettings.temperatureTolerance = parseFloat(this.calSettings.temperatureTolerance);
                }
                if (this.calSettings.discHeightTolerance != this.original.discHeightTolerance && this.calSettings.discHeightTolerance != "") {
                    CalSettings.discHeightTolerance = parseFloat(this.calSettings.discHeightTolerance);
                }
                if (this.calSettings.zeroTolerance != this.original.zeroTolerance && this.calSettings.zeroTolerance != "") {
                    CalSettings.zeroTolerance = parseFloat(this.calSettings.zeroTolerance);
                }
                if (this.calSettings.calibrationIntervalStatus != this.original.calibrationIntervalStatus) {
                    CalSettings.calibrationIntervalStatus = this.calSettings.calibrationIntervalStatus;
                }
                let interval = {}
                if (this.calSettings.calibrationInterval.height != this.original.calibrationInterval.height) {
                    interval.height = parseInt(this.calSettings.calibrationInterval.height);
                }
                if (this.calSettings.calibrationInterval.temperature != this.original.calibrationInterval.temperature && this.calSettings.calibrationInterval.temperature != "") {
                    interval.temperature = parseInt(this.calSettings.calibrationInterval.temperature);
                }
                if (this.calSettings.calibrationInterval.position != this.original.calibrationInterval.position && this.calSettings.calibrationInterval.position != "") {
                    interval.position = parseInt(this.calSettings.calibrationInterval.position);
                }
                if (this.calSettings.calibrationInterval.frequency != this.original.calibrationInterval.frequency && this.calSettings.calibrationInterval.frequency != "") {
                    interval.frequency = parseInt(this.calSettings.calibrationInterval.frequency);
                }
                if (Object.keys(interval).length > 0) {
                    CalSettings.calibrationInterval = interval;
                }
                if (Object.keys(CalSettings).length === 0 && CalSettings.constructor === Object) {
                    this.showSaveProgress = false;
                    this.showSaveSuccess = true;
                    this.watcher();
                    setTimeout(() => {
                        this.saveDisabled = true;
                        this.showSaveSuccess = false;
                    }, 800)
                } else {
                    this.$store.dispatch('postAxiosNoSetter', ['disi/calibration/settings', CalSettings])
                        .then((response) => {
                            if (response.status === 200) {
                                this.showSaveProgress = false;
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(() => {
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            } else {
                                this.showSaveProgress = false;
                            }
                        })
                }
            }
        },
        watcher() {
            //copy CalSettings to original for comparison
            this.original = JSON.parse(JSON.stringify(this.calSettings));

            let temperatureTolerance = this.$watch('$store.state.calSettings.temperatureTolerance', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let discHeightTolerance = this.$watch('$store.state.calSettings.discHeightTolerance', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let zeroTolerance = this.$watch('$store.state.calSettings.zeroTolerance', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let calibrationIntervalStatus = this.$watch('$store.state.calSettings.calibrationIntervalStatus', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let calibrationIntervalHeight = this.$watch('$store.state.calSettings.calibrationInterval.height', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let calibrationIntervalTemperature = this.$watch('$store.state.calSettings.calibrationInterval.temperature', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let calibrationIntervalPosition = this.$watch('$store.state.calSettings.calibrationInterval.position', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let calibrationIntervalFrequency = this.$watch('$store.state.calSettings.calibrationInterval.frequency', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function () {
                temperatureTolerance();
                discHeightTolerance();
                zeroTolerance();
                calibrationIntervalStatus();
                calibrationIntervalHeight();
                calibrationIntervalTemperature();
                calibrationIntervalPosition();
                calibrationIntervalFrequency();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/disi/calibration/settings', 'calSettings'])
            .then(response => {
                if (response.status === 200) {
                    this.watcher();
                }
            });
    },
    beforeRouteLeave(to, from, next) {
        if (this.saveDisabled) {
            next()
        } else {
            this.$confirm({
                message: this.$t('alerts.unsavedChanges'),
                show: true,
                cancelText: this.$t('general.cancel'),
                continueText: this.$t('general.continue')
            })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">

</style>